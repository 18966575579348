




import Vue from "vue";
import OMSApi from "./api/OMSApi";

export default Vue.extend({
  name: "App",

  data: () => ({
    //
  }),
  mounted() {
    Vue.prototype.$omsApi = new OMSApi(async () =>
      this.$auth.getTokenSilently({})
    );
  },
});
